export const utils = {
    convertToThaiDate(date: string | undefined) {

        if (!date) throw new Error("Date is not Undefined");

        const d = new Date(date);
        const month = ["ມັງກອນ", "ກຸມພາ", "ມີນາ", "ເມສາ", "ພຶດສະພາ", "ມິຖຸນາ",
            "ກໍລະກົດ", "ສິງຫາ", "ກັນຍາ", "ຕຸລາ", "ພະຈິກ", "ທັນວາ"];
        return d.getDate() + ' ' + month[d.getMonth()] + ' ' + (d.getFullYear() + 543)
    },
    numberWithCommas(x: string) {
        const value = parseFloat(x).toFixed(2);
        const num = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num;
    },
}